<template>
  <div>
      <ul>
          <li><router-link :to="{name: 'devteamicons'}">Icons</router-link></li>
          <li><router-link :to="{name: 'devteamdates'}">Dates</router-link></li>
      </ul>
      <h6>This is our TODO list so we don't forget</h6>
      <ul>
        <li><p>Build out INET section of the system</p></li>
        <li><p>Build out dynamic dashboards with the ability to manage and create them</p></li>
        <li><p>Build out http://localhost:8080/my/settings#</p></li>
      </ul>
       <div class="flex w-full h-screen items-center justify-center text-center" id="app">
  <div class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
      class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
  
    <label for="assetsFieldHandle" class="block cursor-pointer">
      <div>
        Explain to our users they can drop files in here 
        or <span class="underline">click here</span> to upload their files
      </div>
    </label>
  </div>
</div>
<fileUpload :files="filelist" :folderId="1" ></fileUpload>
  </div>
</template>

<script>
import fileUpload from '@/components/docs/fileupload'
export default {
  components: {
    fileUpload,
  },
  data () {
    return {
       filelist: [] // Store our uploaded files
    }
  },
  created() {
    this.getView()
  },
  methods: {
    getView() {
        this.$http.get('/ViewAccess/devteam')
        .then(() => {
        })
        .catch(() => {
        })
    },
     onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }, 
  }

}
</script>

<style>

</style>