<template>
  <div class="fileUploadBx" v-if="this.intFiles.length">
      <h5 class="text-center mt-2">Uploading...</h5>
    <ul class="mt-1"  >
      <li class="text-sm p-1" v-for="file in intFiles" :key="`file-${file}`">
        <div class="row">
            <div class="col-md-auto" style="padding-right: 4px;">
                <a-icon class="text-success" style="margin-top:-3px;" type="sync" spin />
            </div>
            <div class="col-md" style="padding-top:14px;padding-left: 0px;">
                <span style="font-size:11px;">{{file.name}}</span>
            </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
//base from.... https://codepen.io/stenvdb/pen/wvBoYQO
export default {
  delimiters: ['${', '}'], // Avoid Twig conflicts
  props:{
   files: {
       type: Array,
       default: null 
   },
   folderId: {
       type: Number,
       default: null 
   },
   isMyRoot: {
       type: Boolean,
       default: false 
   },
   fileVersionSelectedId: {
       type: Number,
       default: null 
   },
  },
  data () {
    return {
      intfileVersionSelectedId: null,
        intFiles: [],
        fileInfos: '',
        progress: '',
        newId: null,
    }
  },
   watch: {
     // folderId: function () {
     //    alert(this.folderId) 
     // },
     fileVersionSelectedId: function () { 
        this.intfileVersionSelectedId = this.fileVersionSelectedId
     },
    files: function () { 
            this.intFiles = this.files
            this.processFiles()
        }
  },
  methods: {
    finishedUploading () {
        this.intfileVersionSelectedId = null
        this.intFiles = []
        let folderLocaion  = {id: this.folderId , myRoot: this.isMyRoot}
        this.$emit('uploadFinished',folderLocaion)
        this.$emit('uploaded', this.newId)
    },
    processFiles () {
        if (this.intFiles.length){
          this.intFiles.forEach((file,index) => {
            this.startUpload(this.intFiles[index])
          });
        }
    },
    startUpload(file) {
      this.upload(file, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
	// .then(() => {
    //         alert('done')
    //     //alert(response.data.message);
    // })
    // .then(() => {
    //       //this.fileInfos = files.data;
    // })
    // .catch(() => {
    //       alert("Could not upload the file!");
    // })
    .then(() => {
            setTimeout(() => this.finishedUploading() , 3000);
    })
    .catch((error) => {
      this.finishedUploading()
        if (error != null && error.message != null) {
            alert(error.message)
        } else {
                  alert("Could not upload the file. Please contact support!")
        }
    });
    },
    upload(file, onUploadProgress) {
    let formData = new FormData()
    formData.append('fileVersionSelectedId', this.intfileVersionSelectedId)
    formData.append('folderId', this.folderId)
    formData.append('isMyRoot', this.isMyRoot)
    formData.append("file", file)
      return this.$http.post("/docs/upload/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      }).then((response) => {
        this.newId = response.data.id
      })
      .catch(() => {
         this.$message.success('There has been an error. Please try again later or contact support.')
      });
    }
  }

}
</script>

<style>
.fileUploadBx {
    padding: 0px 10px 0px 10px;
    border: 2px solid #ebeef2;
    border-radius: 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
}
</style>